import React from "react";
import Tilt from "react-parallax-tilt";

function Introduce() {
  const defaultAvatar = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKQgw3xZTxibPt2d0cJpoCdpCHCE9slMel8g&s";
  
  const team = [
    { name: "Muhammad Owais", role: "Full Stack Developer", image: defaultAvatar, position: "left" },
    { name: "Muhammad Amin Motiwala", role: "Graphic Design & Figma", image: defaultAvatar, position: "bottom" },
    { name: "Muhammad Junaid Ali", role: "SEO and Digital Marketing", image: defaultAvatar, position: "right" },
    { name: "Muhammad Umar Jafar", role: "Laravel PHP Developer", image: defaultAvatar, position: "top" }
  ];

  return (
    <div className="flex flex-wrap items-center justify-center flex-col lg:flex-row relative overflow-hidden">
      <img
        src="./star.jpg"
        alt=""
        className="h-full w-full object-cover opacity-10 absolute bottom-0"
      />
      <div className="w-full lg:w-1/2 h-1/2 lg:h-full flex items-center justify-center flex-col my-10 lg:gap-10">
        <h1 className="flex text-white text-2xl lg:text-4xl gap-2 my-10">
          IT<p className="text-[#ad50eb]">Revolution</p> is here
        </h1>
        <div className="flex items-center m-auto justify-center w-11/12 lg:w-3/4 lg:mr-10">
          <span className="flex flex-col gap-3 text-white items-start text-base justify-center font-semibold lg:text-lg">
            <p>
              At Softtechnix we concentrate in delivering successful projects to both local and international clients in the Software, Database handling, Web Designing, Mobile Applications, Digital Marketing, and Graphic Designing fields. We are committed to identifying highly innovative individuals who, through our design-centric approach and project execution tactics, can ensure the successful delivery of projects to our clients.
            </p>
          </span>
        </div>
      </div>

      <div className="w-full lg:w-1/2 h-full flex items-center justify-center p-10">
        <Tilt>
          <img src="man.png" alt="" className="object-cover" />
        </Tilt>
      </div>
      
      <div className="w-full mt-20">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-bold tracking-tight mb-4 text-white">
              MEET OUR <span className="bg-[#ad50eb] text-white px-2">TEAM</span>
            </h1>
          </div>

          <div className="relative h-[500px] mb-16">
            {team.map((member) => (
              <div
                key={member.name}
                className={`absolute animate-slide-fade-up ${
                  member.position === 'top' ? 'top-0 left-1/2 -translate-x-1/2' :
                  member.position === 'right' ? 'top-1/2 right-0 -translate-y-1/2' :
                  member.position === 'bottom' ? 'bottom-0 left-1/2 -translate-x-1/2' :
                  'top-1/2 left-0 -translate-y-1/2'
                } transition-all duration-300 hover:scale-105 hover:shadow-lg`}
              >
                <div className="relative w-40 h-40 rounded-full overflow-hidden border-4 border-white shadow-lg bg-white">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-full object-contain p-2"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
            {team.map((member) => (
              <div key={member.name} className="space-y-1">
                <h3 className="font-bold text-md text-white">{member.name}</h3>
                <p className="text-sm md:text-base lg:text-lg text-gray-300 font-semibold">
                  {member.role}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>


      <style jsx>{`
        @keyframes slide-fade-up {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-slide-fade-up {
          animation: slide-fade-up 0.8s ease-in-out;
        }
      `}</style>
    </div>
  );
}

export default Introduce;
