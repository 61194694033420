import React from "react";
import { BsGithub } from "react-icons/bs";

import { FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

function Footer() {
  return (
    <>
    <div className="flex items-center justify-evenly bg-[#0F0416] text-white flex-wrap px-3 py-4 footer">
    <p className="text-sm font-semibold text-center m-2">
        Copyright © 2023 Softtechnix 
      </p>
      <span className="flex items-center justify-center gap-7 m-2">
        <a
          href="https://github.com/MuhammadOwais4"
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          <BsGithub />
        </a>
        <a
          href="https://www.linkedin.com/in/muhammad-owais-0b7256230/"
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://www.instagram.com/muhammadowais368/"
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          <AiFillInstagram />
        </a>
      </span>
    </div>
    </>
  );
}

export default Footer;
