import React from "react";
import WhoIM from "../components/WhoIM";
import ProjectCard from "../components/ProjectCard";

function About() {
  return (
    <div>
      <WhoIM />
      <h1 className="m-auto flex items-center w-full justify-center gap-2 text-gray-200 font-medium text-4xl my-3">
    Our <p className="text-[#ad50eb]">Projects </p>
  </h1>
      <div className="flex flex-wrap items-center justify-center flex-col lg:flex-row relative overflow-hidden ">
      <img
        src="./star.jpg"
        alt=""
        className="h-full w-full object-cover opacity-20 absolute bottom-0"
      />
      <div className="z-20 flex items-center justify-center gap-3 m-2 flex-wrap">
        <ProjectCard
          name={"Coindom Crypto Coins 💰"}
          image={"/project/img1.png"}
          about={
            "Coindom is a cryptocurrency app that provides users with real-time information on various cryptocurrencies. With Coindom, you can view the details of any crypto, including its chart for different periods."
          }
          demo={"https://coindom-gold.vercel.app/"}
          
        />
        <ProjectCard
          name={"lumapix"}
          image={"/project/img2.png"}
          about={
            "Meet lumapix – the responsive image download app that lets you search and download images with ease."
          }
          demo={"https://lumapix.vercel.app/"}
          
        />
        <ProjectCard
          name={"Out Fit(e commerce) 🏪"}
          image={"/project/img3.png"}
          about={
            "Out Fit: The Adaptive Online Clothing Store Looking for an online clothing store that anticipates your needs? Look no further than Outfit Our platform is designed with both men and women in mind, offering a vast selection of clothes and accessories. With its fully responsive system, FASHCAVE is a breeze to navigate and shop."
          }
          demo={"https://react-ecomm-ten.vercel.app/"}
          code={"https://github.com/MuhammadOwais4/react-ecomm-full-Api"}
        />
        <ProjectCard
          name={"memory-game (on Desktop)"}
          image={"/project/img4.png"}
          about={
            "Meet memory-game -Responisve easy Game free online websites platfrom"
          }
          demo={"https://memory-game-nine-phi.vercel.app/"}
        />
        <ProjectCard
          name={"GYM Websites"}
          image={"/project/img5.png"}
          about={
            " Gym websites typically provide details about membership options, including pricing for different plans, contract terms, and any special promotions or discounts. They might also outline what is included in each membership level."
          }
          demo={"https://health-hub-gym.vercel.app/"}
     
        />
      </div>
      </div>
    </div>
  );
}

export default About;
