import React from "react";
import ProjectCard from "./ProjectCard";

function Firebase() {

    return (
        <>
            <h1 className="text-4xl font-mono text-center text-white my-4 lg:my-8">
            Mobile Applications  Services
            </h1>
            <div className="relative w-full lg:w-4/5 mx-auto flex items-center justify-center flex-wrap my-5">
                <img
                    src="./star.jpg"
                    alt=""
                    className="h-full w-full object-cover opacity-10 absolute bottom-0"
                />
                <div className="z-20 flex items-center justify-center gap-3 m-2 flex-wrap">
                    <ProjectCard
                        name={"E - Commerce Aplliction" }
                        image={"./project/img14.jpg"}
                       
        
                    />
                    <ProjectCard
                        name={"Food Apllication"}
                        image={"./project/img15.jpg"}
                       
                    
                    />
                    <ProjectCard
                        name={"Ambulance Booking "}
                        image={"./project/img16.jpg"}
                       
                        
                    />
                    <ProjectCard
                        name={"Gym Application"}
                        image={"./project/img17.jpg"}
                       
                        
                    />
                    
                    <ProjectCard
                        name={"Clinic App  For Patient"}
                        image={"./project/img18.jpg"}
                       
                        
                    />
                </div>
            </div>
        </>
    );
}

export default Firebase;
