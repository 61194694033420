import React from "react";

import Techstack from "../components/Techstack";
function Project() {
  return (
    <>    
  <h1 className="m-auto flex items-center w-full justify-center gap-2 text-gray-200 font-medium text-4xl my-3">
    We <p className="text-[#ad50eb]">Offers </p>
   </h1>
   <Techstack />

   </>
  );
}

export default Project;
