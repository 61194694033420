import React from "react";
import ProjectCard from "./ProjectCard";

function NODE() {

    return (
        <>
            <h1 className="text-4xl font-mono text-center text-white my-4 lg:my-8">
                Frontend / Backend Services
            </h1>
            <div className="relative w-full lg:w-4/5 mx-auto flex items-center justify-center flex-wrap my-5">
                <img
                    src="./star.jpg"
                    alt=""
                    className="h-full w-full object-cover opacity-10 absolute bottom-0"
                />
                <div className="z-20 flex items-center justify-center gap-3 m-2 flex-wrap">
                    <ProjectCard
                        name={"E - Learning Online"}
                        image={"./project/img10.PNG"}
                        about={
                            "Our E-Learning platform empowers students to learn programming languages at their own pace, offering comprehensive resources and interactive content."
                        }
                        demo={"https://e-learing-lilac.vercel.app/"}
                    />
                    <ProjectCard
                        name={"Sneaker Shoes"}
                        image={"./project/img11.PNG"}
                        about={
                            "Sneaker Shoes is your go-to online store for the latest sneaker trends, offering a wide variety of styles to suit any taste."
                        }
                        demo={"https://snkr-hub-nu.vercel.app/"}
                    />
                    <ProjectCard
                        name={"GAlARIA"}
                        image={"./project/img13.PNG"}
                        about={
                            "GAlARIA is your adaptive online clothing store designed to anticipate your needs with a vast selection of clothing and accessories for everyone."
                        }
                        demo={"https://g-al-aria.vercel.app/"}
                    />
                    <ProjectCard
                        name={"Snake-game (on Desktop)"}
                        image={"./project/img12.PNG"}
                        about={
                            "Enjoy the classic Snake Game - a responsive and engaging online gaming experience available for free."
                        }
                        demo={"https://snake-game-lime-rho.vercel.app/"}
                    />
                </div>
            </div>
        </>
    );
}

export default NODE;
