import React from "react";
import { AiFillHtml5 } from "react-icons/ai";
import {
  FaCss3Alt,
  FaReact,
  FaSass,
  FaWordpress,
  FaAngular,
  FaNodeJs,
  FaPhp,
  FaPython,
  FaLaravel,
  FaFigma,
  FaPaintBrush,
} from "react-icons/fa";
import { DiJavascript, DiMysql, DiDjango } from "react-icons/di";
import {
  SiTypescript,
  SiTailwindcss,
  SiFirebase,
  SiRedux,
  SiExpress,
  SiMongodb,
  SiFlask,
  SiDotnet,
  SiFlutter,
  SiDart,
  SiSupabase,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiReact,
} from "react-icons/si";
import { Link } from "react-router-dom";

function Techstack() {
  const MobileServices = [
    { icon: <Link to="/Firebase"><SiFirebase className="Firebase" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><SiRedux className="Redux" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><SiExpress className="Express" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><SiMongodb className="Mongodb" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><SiFlask className="Flask" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><DiDjango className="Django" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><FaNodeJs className="NodeJs" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><FaLaravel className="Laravel" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><DiMysql className="MySQL" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><SiFlutter className="Flutter" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><SiDart className="Dart" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><SiSupabase className="Supabase" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Firebase"><SiReact className="ReactNative" fontSize={100} color="#fff" /></Link> },
  ];

  const FrontendBackendServices = [
    { icon: <Link to="/HTML"><AiFillHtml5 fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/HTML"><FaSass fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/HTML"><FaCss3Alt fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><FaReact fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/HTML"><FaWordpress fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/HTML"><DiJavascript className="javascriptIcon" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><SiTypescript className="typescriptIcon" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><FaAngular fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><SiTailwindcss className="tailwind" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><SiFirebase className="firebase" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><SiRedux className="redux" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><SiExpress className="express" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><SiMongodb className="mongodb" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><SiFlask className="flask" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><SiDotnet className="dotnet" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><DiDjango className="django" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><FaNodeJs className="nodejs" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><FaPhp className="php" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><FaLaravel className="laravel" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><DiMysql className="mysql" fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Node"><FaPython className="python" fontSize={100} color="#fff" /></Link> },
  ];
   const GraphicDesignServices = [
    { icon: <Link to="/Adobephotoshop"><SiAdobephotoshop  fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Adobephotoshop"><SiAdobeillustrator   fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Adobephotoshop"><SiAdobephotoshop  fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Adobephotoshop"><FaFigma fontSize={100} color="#fff" /></Link> },
    { icon: <Link to="/Adobephotoshop"><FaPaintBrush fontSize={100} color="#fff" /></Link> },
  ];


  return (
    <>
      <h1 className="text-4xl font-mono text-center text-white my-4 lg:my-8 italic">
        Frontend / Backend Development
      </h1>
      <div className="relative w-full lg:w-4/5 mx-auto flex items-center justify-center flex-wrap my-5">
        <img
          src="./star.jpg"
          alt=""
          className="h-full w-full object-cover opacity-10 absolute bottom-0"
        />
        {FrontendBackendServices.map((item, i) => (
          <div
            className="w-36 md:w-48 lg:w-60 h-36 flex items-center justify-center m-3 border border-[#df8de2] bg-black/30 z-20 rounded-sm custom-animation"
            key={i}
          >
            {item.icon}
          </div>
        ))}
      </div>
      <h1 className="text-4xl font-mono text-center text-white my-4 lg:my-8 italic">
       Mobile App Development
        </h1>
      <div className="relative w-full lg:w-4/5 mx-auto flex items-center justify-center flex-wrap my-5">
        {MobileServices.map((item, i) => (
          <div
            className="w-36 md:w-48 lg:w-60 h-36 flex items-center justify-center m-3 border border-[#df8de2] bg-black/30 z-20 rounded-sm custom-animation"
            key={i}
          >
            {item.icon}
          </div>
        ))}
      </div>
      <h1 className="text-4xl font-mono text-center text-white my-4 lg:my-8 italic" >
         Graphic Designing 
      </h1>
      <div className="relative w-full lg:w-4/5 mx-auto flex items-center justify-center flex-wrap my-5">
        {GraphicDesignServices.map((item, i) => (
          <div
            className="w-36 md:w-48 lg:w-60 h-36 flex items-center justify-center m-3 border border-[#df8de2] bg-black/30 z-20 rounded-sm custom-animation"
            key={i}
          >
            {item.icon}
          </div>
        ))}
      </div>
    </>
  );
}

export default Techstack;
