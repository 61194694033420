import React from "react";
import ProjectCard from "./ProjectCard";

function Adobephotoshop() {

    return (
        <>
            <h1 className="text-4xl font-mono text-center text-white my-4 lg:my-8">
            Graphic Design  Services
            </h1>
            <div className="relative w-full lg:w-4/5 mx-auto flex items-center justify-center flex-wrap my-5">
                <img
                    src="./star.jpg"
                    alt=""
                    className="h-full w-full object-cover opacity-10 absolute bottom-0"
                />
                <div className="z-20 flex items-center justify-center gap-3 m-2 flex-wrap">
                    <ProjectCard
                    name={"Logo Design"}
                    image={"./project/img19.jpg"}
                    />
                    <ProjectCard
                    name={"Social Media Post"}
                        image={"./project/img21.jpg"}
                    />
                </div>
            </div>
        </>
    );
}

export default Adobephotoshop;
