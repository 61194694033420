import React from "react";
import Hero from "../components/Hero";
import Interduce from "../components/Interduce";

function Home() {
  return (
    <div>
      <Hero />
      <Interduce />
    </div>
  );
}

export default Home;
