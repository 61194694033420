import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AnimeCursor from "./components/AnimeCursor";
import ScrollToTop from "./components/ScrollToTop";
import Html from "./components/Html";
import NODE from "./components//NODE";
import Firebase from "./components/Firebase";
import Adobephotoshop from "./components/Adobephotoshop";

function App() {
  return (
    <div className="bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900">
      <AnimeCursor />
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/HTML" element={<Html />} />
        <Route path="/NODE" element={<NODE />} />
        <Route path="/Firebase" element={<Firebase />} />
        <Route path="/Adobephotoshop" element={<Adobephotoshop />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
