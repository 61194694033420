import React from "react";
import ProjectCard from "../components/ProjectCard";
 function Html() {
  return (
<>
<h1 className="text-4xl font-mono text-center text-white my-4 lg:my-8">
        Frontend  Services
      </h1>
      <div className="relative w-full lg:w-4/5 mx-auto flex items-center justify-center flex-wrap my-5">
        <img
          src="./star.jpg"
          alt=""
          className="h-full w-full object-cover opacity-10 absolute bottom-0"
        />
        <div className="z-20 flex items-center justify-center gap-3 m-2 flex-wrap">
        <ProjectCard
          name={"MY CHEF"}
          image={"/project/img6.jpg"}
          about={
            "Coindom is a cryptocurrency app that provides users with real-time information on various cryptocurrencies. With Coindom, you can view the details of any crypto, including its chart for different periods."
          }
          demo={"https://khaliqiaglobalfoods-a6294.web.app/"}
          
        />
        <ProjectCard
          name={"GIFTS-Websites"}
          image={"/project/img7.jpg"}
          about={
            "Meet lumapix – the responsive image download app that lets you search and download images with ease."
          }
          demo={"https://gift-web-two.vercel.app/"}
          
        />
        <ProjectCard
          name={"Solartec"}
          image={"/project/img8.jpg"}
          about={
            "Out Fit: The Adaptive Online Clothing Store Looking for an online clothing store that anticipates your needs? Look no further than Outfit Our platform is designed with both men and women in mind, offering a vast selection of clothes and accessories. With its fully responsive system, FASHCAVE is a breeze to navigate and shop."
          }
          demo={"https://new-rabia-enterprise.vercel.app/"}
        />
        <ProjectCard
          name={"Ludo-game (on Desktop)"}
          image={"/project/img9.jpg"}
          about={
            "Meet Ludo-game -Responisve easy Game free online websites platfrom"
          }
          demo={"https://ludo-app-rho.vercel.app/"}
        />
        </div>
      </div>

</>
  )
   
}
export default Html