import React, { useState } from "react";
import { db } from "./Auth";  
import { collection, addDoc } from "firebase/firestore";  // Firestore methods

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form fields before submitting
    if (!formData.name || !formData.email || !formData.message) {
      setErrors({
        name: !formData.name,
        email: !formData.email,
        phone: !formData.phone,  // Optional validation for phone number
        subject: !formData.subject,  // Optional validation for subject
        message: !formData.message,
      });
      return;
    } else {
      setErrors({});
    }

    setLoading(true);

    try {
      // Submit form data to Firestore
      await addDoc(collection(db, "contacts"), {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        subject: formData.subject,
        message: formData.message,
        createdAt: new Date(),  // Optional: store the time of submission
      });

      setSuccessMessage("Form submitted successfully!");
      setFormData({ name: "", email: "", phone: "", subject: "", message: "" });

      // Clear the success message after 5 seconds
      setTimeout(() => setSuccessMessage(""), 5000);
    } catch (error) {
      console.error("Error submitting form to Firestore:", error);
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-white text-2xl lg:text-4xl gap-2 font-bold mt-4">
        Contact <span className="text-[#ad50eb]">Us</span>
      </h1>
      <form onSubmit={handleSubmit} className="z-20 w-full max-w-lg p-4 md:p-8">
        <div className="mb-5">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            className={`w-full px-4 py-2 rounded shadow-md ${
              errors.name ? "border-red-500" : ""
            }`}
          />
          {errors.name && (
            <p className="text-red-500 text-xs">Name is required</p>
          )}
        </div>
        <div className="mb-5">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className={`w-full px-4 py-2 rounded shadow-md ${
              errors.email ? "border-red-500" : ""
            }`}
          />
          {errors.email && (
            <p className="text-red-500 text-xs">Email is required</p>
          )}
        </div>
        <div className="mb-5">
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            className={`w-full px-4 py-2 rounded shadow-md ${
              errors.phone ? "border-red-500" : ""
            }`}
          />
          {errors.phone && (
            <p className="text-red-500 text-xs">Phone is required</p>
          )}
        </div>
        <div className="mb-5">
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="Subject"
            className={`w-full px-4 py-2 rounded shadow-md ${
              errors.subject ? "border-red-500" : ""
            }`}
          />
          {errors.subject && (
            <p className="text-red-500 text-xs">Subject is required</p>
          )}
        </div>
        <div className="mb-5">
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            className={`w-full px-4 py-2 rounded shadow-md ${
              errors.message ? "border-red-500" : ""
            }`}
          />
          {errors.message && (
            <p className="text-red-500 text-xs">Message is required</p>
          )}
        </div>
        <button
          type="submit"
          className={`w-full px-6 py-2 rounded shadow-md text-white ${
            loading ? "bg-gray-400" : "bg-purple-600 hover:bg-purple-700"
          } transition duration-300 ease-in-out my-3`}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
      {successMessage && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-black z-50">
          <div className="bg-white p-4 md:p-8 rounded shadow-md text-center">
            <p className="text-green-500 text-lg">{successMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactForm;
