import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your Firebase configuration object from the Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyCB68DEQjlBqcTn7Yv5Rj2iNxf40DxmHrI",
  authDomain: "softtechnix-1cce1.firebaseapp.com",
  projectId: "softtechnix-1cce1",
  storageBucket: "softtechnix-1cce1.appspot.com",
  messagingSenderId: "40284211739",
  appId: "1:40284211739:web:6917886a7562b64d16c6be",
  measurementId: "G-3F8FQSSY4R",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
